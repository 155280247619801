import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "project", "reviewer", "modal"]

  connect() {
    $("[data-toggle='tooltip']").tooltip()
  }

  saveChanges() {
    const modal = this.modalTarget
    const user = this.element.dataset["user"]
    const project = this.projectTarget
    const reviewer = this.reviewerTarget
    let   object = JSON.parse(this.listTarget.value)

    $.ajax({
      url: "/reviews/update_reviewer",
      type: "PATCH",
      data: {
        user_id: user,
        manager_id: object.manager_id,
        manager: object.manager,
        project: object.project
      },
      success: () => {
        console.log("Success")
        reviewer.innerHTML = object.manager
        project.innerHTML = object.project
      },
      error: () => { console.log("Error") }
    })
    $(`#${modal.id}`).modal("hide")
    $('body').on('hidden.bs.modal', '.modal', function() {
      $('.btn').blur()
    });
  }
}
