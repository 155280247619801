import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["comment", "project"]
  static values = {
    projects: Array,
    country: String
  }

  connect() {
    const country = this.countryValue
    $("#new_employee_notification_project_id").on("select2:select", function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
    if (country === "UA") {
      this.handleHardware()
    }
  }

  handleHardware() {
    const project = this.projectTarget
    const projects = this.projectsValue
    const comment = this.commentTarget
    const yesButton = document.getElementById("new_employee_notification_hardware_true")
    const noButton = document.getElementById("new_employee_notification_hardware_false")

    for (let index = 0; index < projects.length; index++) {
      if (Object.keys(projects[index])[0] == project.value) {
        yesButton.checked = true
        noButton.disabled = true
        comment.disabled = false
        comment.readOnly = true
        comment.value = Object.values(projects[index])[0]
        return
      }
    }
    comment.readOnly = false
    noButton.disabled = false
    comment.value = ""
  }
}
