import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]

  toggleButton() {
    const button = this.buttonTarget

    button.checked = true
  }
}
