import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["relationship", "other_relationship"]

  handleSelectChange() {
    let relationship = this.relationshipTarget
    let otherRelationship = this.other_relationshipTarget

    if (!relationship.value) {
      return
    } else if (relationship.value == "Other...") {
      otherRelationship.disabled = false
    } else {
      otherRelationship.disabled = true
      otherRelationship.value = ""
    }
  }
}
