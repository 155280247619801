import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "target" ];

  initialize() {
    let fieldValue = this.targetTarget.dataset.field
    this.populateSelect(this.sourceTarget.value, fieldValue ? fieldValue : null)
  }

  handleSelectChange() {
    this.populateSelect(this.sourceTarget.value);
  }

  populateSelect(sourceId, targetId = null) {
    if (!sourceId) { return }

    fetch(`/${this.data.get("sourceRoutePart")}/${this.data.get("targetRoutePart")}/${sourceId}`, {
      credentials: "same-origin"
    })
      .then(response => response.json())
      .then(data => {
        const selectBox = this.targetTarget
        selectBox.innerHTML = ""
        data.forEach(item => {
          const opt = document.createElement("option")
          opt.value = item
          opt.innerHTML = item
          opt.selected = targetId == item
          selectBox.appendChild(opt)
        })
      })
  }
}
