import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sample", "source"]

  connect() {
    const sample = this.sampleTarget
    const source = this.sourceTarget

    source.value = sample.innerHTML
  }

  copyToClipboard() {
    const source = this.sourceTarget

    source.select()
    source.setSelectionRange(0, 99999)
    document.execCommand("copy")
  }
}
