import { Controller } from "stimulus"

export default class extends Controller {
  disableGroup() {
    let btnGroup = this.element
    let buttons = btnGroup.getElementsByTagName('a')

    buttons.forEach(button => {
      button.classList.add("disabled")
    })
  }
}
