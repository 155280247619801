import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".fa-arrows",
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()

    data.append("ordinal", event.newIndex + 1)
    $.ajax({
      url: this.data.get("url").replace(":id", id),
      type: "PATCH",
      data: {
        ordinal: event.newIndex + 1
      }
    })
  }
}
