import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["inputType", "range"]

  connect() {
    this.showRange();
  }

  showRange() {
    const type = this.inputTypeTarget;
    const range = this.rangeTarget;
    if (type.value === "range") {
      range.classList.remove("hidden");
    } else { range.classList.add("hidden"); }
  }
}
