import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ['overlay', 'uploads', '']

  connect () {
    StimulusReflex.register(this)

    console.log('documents connect', this.element, this.overlayTarget)

    this.input = document.createElement('input');
    this.input.setAttribute('type', 'file');

    window.addEventListener('drop', (event) => { event.preventDefault() })

    this.element.addEventListener('dragover', this.onDragover)
    this.element.addEventListener('dragleave', this.onDragleave)
    this.element.addEventListener('drop', this.onDrop)
  }

  onDragover = (event) => {
    event.preventDefault()
    this.element.classList.add('dragover')
  }

  onDragleave = (event) => {
    event.preventDefault()
    this.element.classList.remove('dragover')
  }

  onDrop = (event) => {
    event.preventDefault()
    this.element.classList.remove('dragover')

    const files = event.dataTransfer.files;
    console.log(files)
    Array.from(files).forEach(file => this.uploadFile(file))
  }

  uploadFile = (file) => {
    this.stimulate('Document#before_upload',
                   this.element,
                   { filename: file.name, content_type: file.type, byte_size: file.size })
    // your form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url
    const url = this.element.dataset.directUploadUrl
    const upload = new DirectUpload(file, url)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.error(error)
      } else {
        this.stimulate('Document#after_upload', this.element, { id: blob.id })
      }
    })
  }
}
