import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "currentCountry" ]
  static values = {
    country: String
  }

  connect() {
    import("country-state-city/lib/country").then(country => {
      this.countryLib = country.default;
      this.start()
    });
  }

  start() {
    for (const element of this.countryLib.getAllCountries()) {
      const option = document.createElement("option")
      option.value = element.name
      option.text = `${element.name}  ${element.flag}`
      this.currentCountryTarget.add(option)
    }

    if (this.countryValue) {
      this.currentCountryTarget.value = this.countryValue
    } else {
      this.currentCountryTarget.value = "Ukraine" // default value for new records
    }

    $("#new_employee_notification_current_country").on("select2:select", function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
  }
}