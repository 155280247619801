import { Controller } from "stimulus"

export default class extends Controller {
  setFilter(event) {
    event.preventDefault();
    let value = event.target.value;
    let params = (new URL(window.location)).searchParams;
    let search = event.target.name;
    (value === undefined || value === '') ? params.delete(search) : params.set(search, value);
    window.location.search = params.toString();
  }
}
