import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tbody", "loadbox"]
  static values = {
    year: String,
    month: String,
    count: Number
  }

  fetchData() {
    const count = this.countValue

    if (count === 0) { return }

    const year = this.yearValue
    const month = this.monthValue
    const tbody = this.tbodyTarget
    const loader = this.loadboxTarget

    if (document.querySelectorAll(`.assignment-item-${month}`).length == 0) {
      loader.classList.remove("hidden")
      fetch(`/workflow/assignments/assignments_by_month.json?month=${month}&year=${year}`, {
        credentials: "same-origin"
      }).then(response => response.json())
        .then(assignments => {
          assignments.forEach(assignment => {
            let elem = document.createElement("tr")
            elem.className = `assignment-item-${month}`
            elem.innerHTML = assignment.template
            tbody.appendChild(elem)
          })
          loader.classList.add("hidden")
        })
    }
  }
}
