import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["date", "notification"]

  changeStartDate() {
    const dateField = this.dateTarget
    const notification = this.notificationTarget
    const selected = notification.options[notification.options.selectedIndex]

    if (notification.options.selectedIndex == 0) { return }

    let words = selected.innerHTML.split(" ")
    let date = words[words.length - 1]

    dateField.value = date
  }
}
