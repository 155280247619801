import { Controller } from "stimulus"

import Select2 from "select2"

export default class extends Controller {
  static targets = [ "source", "target", "city", "street"]

  initialize() {
    let regionValue = this.targetTarget.dataset.region
    $(`#${this.sourceTarget.id}`).select2({
      theme: "bootstrap"
    })
    $(`#${this.sourceTarget.id}`).on("select2:select", function () {
      let event = new Event("change", { bubbles: true })
      this.dispatchEvent(event);
    })
    this.populateSelect(this.sourceTarget.value, regionValue ? regionValue : null)
  }

  handleSelectChange() {
    this.populateSelect(this.sourceTarget.value)
  }

  populateSelect(sourceId, targetId = null) {
    if (!sourceId) { return }
    fetch(`/regions/${sourceId}`, {
      credentials: "same-origin"
    })
      .then(response => response.json())
      .then(data => {
        if (!Object.keys(data).length) {
          data[sourceId] = this.sourceTarget.options[this.sourceTarget.selectedIndex].text
        }
        const selectBox = this.targetTarget
        selectBox.innerHTML = ""

        for(let key in data) {
          const opt = document.createElement("option")
          opt.value = data[key]
          opt.innerHTML = data[key]
          opt.selected = targetId == data[key]

          selectBox.appendChild(opt)
        }
      })
    if (sourceId == "UA") {
      this.cityTarget.placeholder = "Назва населеного пункту українською"
      this.streetTarget.placeholder = "Назва вулиці українською"
    } else {
      this.cityTarget.placeholder = ""
      this.streetTarget.placeholder = ""
    }
  }
}
